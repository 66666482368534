import { useEffect, useState } from 'react';

import { useExperiment, useLoginState } from '@xing-com/hub';

type TcfVendorType = { consent: boolean; id: number }[];

export const useDisplayAds = (): { displayAdsAllowed: boolean } => {
  const isLoggedOut = useLoginState() === 'LOGGED_OUT';
  const [hasMarketingConsent, setHasMarketingConsent] = useState(false);
  const hasDisplayAdsFeature = useExperiment('ABACUS-482') === 'B';

  const isDebugMode =
    global?.window?.localStorage?.getItem('xam_display_ads_debug') === 'true';

  const displayAdsAllowed =
    (isLoggedOut && isDebugMode) ||
    (isLoggedOut && hasMarketingConsent && hasDisplayAdsFeature);

  const handleInitializedEvent = (): void => {
    const tcfVendors: TcfVendorType =
      // @ts-expect-error UC_UI comes from the usercentrics consent script
      window.UC_UI?.getTCFVendors();

    const hasMarketingConsent = tcfVendors.filter(
      (vendor) => vendor.id === 251 // 251 = YieldLove GmbH
    )[0];

    setHasMarketingConsent(hasMarketingConsent?.consent || false);
  };

  useEffect(() => {
    window.addEventListener('UC_UI_INITIALIZED', handleInitializedEvent);

    return () => {
      window.removeEventListener('UC_UI_INITIALIZED', handleInitializedEvent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    displayAdsAllowed,
  };
};
