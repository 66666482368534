export type SlotType = {
  id: string;
  adUnitPath: string;
  size: googletag.GeneralSize;
  refresh?: boolean;
};

export type SlotConfigType = {
  Startpage: {
    [key: string]: SlotType;
  };
  Profile: {
    [key: string]: SlotType;
  };
};

export const adSlotConfig: SlotConfigType = {
  Startpage: {
    Billboard: {
      id: 'div-gpt-ad-1407836097008-0',
      adUnitPath: '/53015287,23277432748/xing.com_d_970x250_1',
      size: [[970, 250]],
    },
    Leaderboard: {
      id: 'div-gpt-ad-1407836123535-0',
      adUnitPath: '/53015287,23277432748/xing.com_d_728x90_2',
      size: [728, 90],
    },
    Rectangle01: {
      id: 'div-gpt-ad-1407836207151-0',
      adUnitPath: '/53015287,23277432748/xing.com_m_300x250_2',
      size: [300, 250],
    },
    Rectangle02: {
      id: 'div-gpt-ad-1407836298563-0',
      adUnitPath: '/53015287,23277432748/xing.com_m_300x250_3',
      size: [300, 250],
    },
  },
  Profile: {
    Leaderboard: {
      id: 'div-gpt-ad-1407836215582-0',
      adUnitPath: '/53015287,23277432748/xing.com_d_728x90_1',
      size: [728, 90],
    },
    // Currently unused
    HalfpageStickyBig: {
      id: 'div-gpt-ad-1407836209939-0',
      adUnitPath: '/53015287,23277432748/xing.com_d_300x600_1',
      size: [300, 600],
    },
    HalfpageStickySmall: {
      id: 'div-gpt-ad-1407836209939-0',
      adUnitPath: '/53015287,23277432748/xing.com_d_160x600_1',
      size: [
        [120, 600],
        [160, 600],
      ],
    },
    // Currently unused
    MobileFixed: {
      id: 'div-gpt-ad-1407836162374-0',
      adUnitPath: '/53015287,23277432748/xing.com_m_320x50_1',
      size: [320, 50],
    },
    Rectangle: {
      id: 'div-gpt-ad-1407836200179-0',
      adUnitPath: '/53015287,23277432748/xing.com_m_300x250_1',
      size: [300, 250],
    },
  },
};
