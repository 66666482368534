import styled from 'styled-components';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';

import { useDisplayAds } from '../../use-display-ads';
import { useAdRefresh } from '../use-ad-refresh';
import { DisplayAdProfileLeaderboard } from './leaderboard-ad';

const AdWrapper = styled.div`
  width: 728px;
  height: 90px;
  position: relative;
  z-index: 1;
`;

export const DisplayAdProfileLeaderboardSlot: React.FC = () => {
  const isSmallScreen = useMatchMedia(mediaSmallOrTiny);
  const { displayAdsAllowed } = useDisplayAds();

  const { refreshDesktop } = useAdRefresh({
    isSmallScreen,
    slots: {
      mobile: '',
      desktop: 'leaderboard',
    },
  });

  if (!displayAdsAllowed || isSmallScreen) {
    return null;
  }

  return (
    <AdWrapper>
      <NoSSR>
        <DisplayAdProfileLeaderboard refresh={refreshDesktop} />
      </NoSSR>
    </AdWrapper>
  );
};
